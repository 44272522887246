import mainjson from './main';

function ifAudioQuiz(data){
    if (data.game_type == 'audioquiz') {
        return true;
    }
    return false;
}

function ifQuiz(data){
    if (data.game_type == 'quiz') {
        return true;
    }
    return false;
}

function ifPraat(data){
    if (data.game_type == 'praat') {
        return true;
    }
    return false;
}

function ifBingo(data){
    if (data.game_type == 'bingo') {
        return true;
    }
    return false;
}


export default function GetData() {
        return {
        "apitype":"games",
        "id":"game_id",
        "options":["edit","insert","delete"],
        "subidname":"game_name",
        "nameSingle":"game",
        "nameMultiple":"games",
        "extraoptions":[
            {
                "name":"Questions",
                "page":"/questions",
                "if":ifAudioQuiz
            },
            {
                "name":"Questions",
                "page":"/textquestions",
                "if":ifQuiz
            },
            {
                "name":"Questions",
                "page":"/langquestions",
                "if":ifPraat
            },
            {
                "name":"Options",
                "page":"/bingooptions",
                "if":ifBingo
            }
        ],
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"game_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Type",
                "field":"game_type",
                "type":"DropDown",
                "options": {
                    "quiz":"Quiz",
                    "audioquiz":"Sounds",
                    "praat":"Praat",
                    "bingo":"Bingo"
                },
                "list":true,
                "required":true,

            },
            "field3":{
                "name":"Distance (km, bingo only)",
                "field":"game_distance",
                "type":"TextInput",
                "subtype":"Number",
                "required":false,
                "list":true,
            },
            "field5":{
                "name":"Sound (OGG, bingo only)",
                "field":"game_audio",
                "type":"SoundUpload",
                "required":false,
                "list":true,
            },
            "field52":{
                "name":"Folder",
                "field":"game_gamefolder_id",
                "type":"DropDown",
                "options": "folders",
                "emptyfirstoption":"Select a folder",
                "defaultsubid":true,
                "filter":true,
                "required":true,
                "list":false
            },
            "field6":{
                "name":"Details",
                "field":"game_info",
                "type":"Display",
                "required":false,
                "list":false,
            },
            
            "field7":{
                "name":"Used in points",
                "field":"point_count",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field8":{
                "name":"Used in points",
                "field":"point_text",
                "type":"Display",
                "required":false,
                "list":false,
            },
            
        }
    }
    
}